<template>
  <div>
    <dynamic-input :input="input" :fields="fields"></dynamic-input>
  </div>
</template>

<script>
import currencyList from '@/constants/finance/currencyList.json';
import { formatCurrency } from '@/utils/formats';
import v from '@/utils/validations';
import DynamicInput from '@/components/FormInput/DynamicInputLegacy.vue';

export default {
  name: 'NewPaymentProvisionForm',
  components: {
    DynamicInput,
  },
  props: {
    input: { type: Object, required: true },
    list: { type: Object, required: true },
  },
  computed: {
    fields() {
      return [
        {
          key: 'date',
          component: 'datepicker',
          type: 'month',
          max: new Date(new Date().getTime() + 90 * 24 * 60 * 60 * 1000).toISOString().substr(0, 7),
          min: new Date(new Date().getTime()).toISOString().substr(0, 7),
          cols: '6',
          label: 'Expense date',
          rules: [v.required()],
        },
        {
          key: 'currency',
          component: 'v-select',
          cols: '6',
          label: 'Currency',
          items: this.currencyList,
          rules: [v.required()],
        },
        {
          key: 'projectId',
          component: 'v-select',
          cols: '6',
          label: 'Project',
          items: this.projectList,
          onChange: this.setProjectData,
          rules: [v.required()],
        },
        {
          key: 'projectGroupId',
          component: 'v-select',
          cols: '6',
          label: 'Project Group',
          items: this.projectGroupList,
          show: !!this.input.projectId,
          readonly: true,
          rules: [v.required()],
        },
        {
          key: 'expenseId',
          component: 'v-select',
          cols: '12',
          label: 'Expense',
          items: this.expenseList,
          onChange: this.setExpenseData,
          show: !!this.input.projectId,
          rules: [v.required()],
        },
        {
          key: 'revenue',
          component: 'v-text-field',
          type: 'number',
          cols: '6',
          label: 'Revenue',
          show: !!this.input.expenseId,
          readonly: true,
          rules: [v.required(), v.money()],
        },
        {
          key: 'valueAddedTax',
          component: 'v-text-field',
          type: 'number',
          cols: '6',
          label: 'Value-added tax (VAT)',
          show: !!this.input.expenseId,
          readonly: true,
          rules: [v.required(), v.money()],
        },
      ].filter((item) => item.show !== false);
    },

    expenseList() {
      return this.list.expenses
        .filter((expense) => expense.projectGroupId === this.input.projectGroupId)
        .map((item) => {
          const outstandingAmount = formatCurrency({ value: item.outstanding, curreny: item.currency });

          return {
            data: item,
            text: `${item.date} (Revenue after expense: ${outstandingAmount.display})`,
            value: item._id,
          };
        });
    },
    projectGroupList() {
      return this.list.projectGroups.map((item) => ({
        text: item.nameTrans.en,
        value: item._id,
      }));
    },
    projectList() {
      return this.list.projects.map((item) => ({
        text: item.nameTrans.en,
        value: item._id,
      }));
    },
    currencyList() {
      return currencyList.map((item) => ({
        text: item.name,
        value: item.code,
      }));
    },
  },
  methods: {
    setProjectData(projectId) {
      const project = this.list.projects.find((item) => item._id === projectId);
      this.input.projectGroupId = project.projectGroupId;
      this.input.panelsTotal = project.panelsTotal;
    },
    setExpenseData(expenseId) {
      const { data } = this.expenseList.find((item) => item.value === expenseId);
      this.input.revenue = data.outstanding;
      this.input.valueAddedTax = data.valueAddedTax;
    },
  },
};
</script>
