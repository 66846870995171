export default [
  'A bee that will not stop eating will eventually become a little chub-bee',
  'Why do bees get married? Because they found their honey!',
  'The worst part about getting stung by bees is that the following day you are going to have to take care of those hives.',
  'What did one bee say to the other when they landed on the same flower? Buzz off.',
  'I know that I have never seen a humming bird but I certainly did see a spelling bee.',
  'We always buy our natural honey from the same bees because they always give us their swarm wishes.',
  'Bees can fly in the rain if they are wearing their little yellow jackets.',
  'When a bee is in your hand, what’s in your eye? Beauty. Because beauty is in the eye of the bee-holder.',
  'The younger generation of bees love the musician Sting.',
  'Speaking of music, all bees can relate to the pop band the Bee Gees.',
  'All bees love the honey-moon part of their relationships more than anything else.',
  'The only one who can protect the Queen Bee is her hub-bee.',
  'What’s a bee’s favorite Spice Girls song? Wanna-bee!',
  'The bee was fired from the barber shop because the only thing he could do was give a buzz-cut.',
  'A bee who is good in math knows exactly what a rhom-buzz is.',
  'A bee that will not stop eating will eventually become a little chub-bee.',
  'A bee styles their hair with a honeycomb.',
  'What do bees like with their sushi? Wasa-bee!',
  'Bee puns really sting.',
  'They asked the beekeeper to move his business out of town because he was creating quite a buzz around town.',
  'The swarm of teenage bees all loved The Beatles and their “Let it Bee” album.',
  'What’s a happy bumblebee’s blood type? Bee positive!',
  'Only bees who are on their best bee-havior get to go to the hive and make honey.',
  'Who’s a bee’s favorite singer? Bee-yoncé.',
  'As soon as the bees were finished making their hive they threw a big house swarming party for the rest of the group.',
  'That bee certainly deserved the promotion at work, he was always so buzzy on the job.',
  'That bee is talking too quietly, it must be a mumble-bee!',
  'That pretentious wasp is just plain snob-bee!',
  'When you cross a doorbell and a bee you wind up with a hum-dinger.',
  'That single bee finally got married because he found his honey.',
  'The worker bee decided to take a vacation to Stingapore last year.',
  'The bee that resides in America is also known as a USB.',
  'Remember, bee puns are good for your health, they give you a dose of Vitamin Bee!',
  'Bee children take the school buzz to get to school.',
  'What kind of bees drop things? Fumble bees!',
  'To bee or not to bee, that is the question!',
];
