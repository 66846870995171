const getters = {
  userAsset(state) {
    return state.userAsset;
  },
  userAssets(state) {
    return state.userAssets;
  },
};

export default getters;
